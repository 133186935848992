import logo from './logo.svg';
import './App.css';
import React, { Component, useEffect, useState, useContext, Suspense, lazy } from 'react';
import MainNavigation from './components/Navigation/MainNavigation/MainNavigation';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import authContext from './AuthContext/authContext';
import Colors from './constants/Colors';
import Cookies from 'js-cookie';
import { userLoginRegisterRequest, checkTokenQuery, updateUser, getUserSummary } from './components/Graph/Authentication/userAuth';
import {homeURL } from './codes';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import Dashboard from './components/Navigation/MainNavigation/Dashboard/Dashboard';
import { loadUserSummary } from './components/Graph/Authentication/userAuth';
import { getForums } from './components/Graph/Authentication/posts';
import { getForumReplies } from './components/Graph/Authentication/postreplies';
import lazyRetry from './components/Navigation/MainNavigation/Utils/lazyRetry';

// const Colors = lazy(() => lazyRetry( () => import('./constants/Colors')));
const Home = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Home/Home')));
const Demos = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Demos/Demos')));
const Features = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Features/Features')));
const Developers = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Developers/Developers')));
const Pricing = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Pricing/Pricing')));
const Login = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Authentication/User/Login')));
const Signup = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Authentication/User/Signup')));
const ForgotPassword = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Authentication/User/ForgotPassword')));
const Footer = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Footer/Footer')));

const Dashboard = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Dashboard/Dashboard')));
const Verify = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Authentication/User/Verify')));
const WaitingModal = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Generic/WaitingModal')));
const Chat = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Events/Chat')));
const Forums = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Home/Forum')));
const SubscriptionPage = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Dashboard/Subscription/SubscriptionPage')));
const PaymentPage = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Dashboard/Subscription/PaymentPage')));
const Purchase = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Dashboard/Subscription/Purchase')));
const Support = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Support/Support')));
const Recordings = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Recording/Recordings')));
const Security = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Foot/Security')));
const VideoCodecs = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Foot/VideoCodecs')));
const Scaling = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Foot/Scaling')));
const RecommendedSettings = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Foot/RecommendedSettings')));
const AboutUs = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Foot/AboutUs')));
const Careers = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Foot/Careers')));
const MediaSFUPrebuilt = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Foot/MediaSFUPrebuilt')));
const Streaming = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Foot/Streaming')));
const PrivacyPolicy = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Foot/PrivacyPolicy')));
const TermsOfService = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Foot/TermsOfService')));
const APIKeys = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Foot/APIKeys')));
const CloudRecording = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Foot/CloudRecording')));
const Docs = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Docs/Docs')));
const SubscriptionInfo = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Dashboard/Subscription/SubcriptionInfo')));
const Documentation = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Documentation/Documentation')));
const Sandbox = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Sandbox/Sandbox')));
const MediaSFUDocs = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/MediaSFUDocs/MediaSFUDocs')));
const VideoCallSetup = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/MediaSFUDocs/VideoCallSetup')));
const Starting = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/Foot/Starting')));
const AIPipelineGuide = lazy(() => lazyRetry( () => import('./components/Navigation/MainNavigation/AIPipelineGuide/AIPipelineGuide')));

const GoogleAnalyticsTag = ({ trackingId }) => (
  <HelmetProvider>
 <Helmet>
    <script async src={`https://www.googletagmanager.com/gtag/js?id=${trackingId}`}></script>
    <script>
      {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${trackingId}');
      `}
    </script>
  </Helmet>
  </HelmetProvider>
);

function App() {

  const KeyLess = process.env.REACT_APP_KEYLESS;

  //check current url and set active link

  // Determine if the current route should hide the footer
  
  const [isFooterHidden, setIsFooterHidden] = useState(false);

  const [token, setToken] = useState(Cookies.get('token'))
  const [userId, setUserId] = useState(Cookies.get('userId'))
  const [username, setUsername] = useState(Cookies.get('username'))
  const [email, setEmail] = useState(Cookies.get('email'))
  const [tempRole, setTempRole] = useState(Cookies.get('tempRole'))
  const [tempUsername, setTempUsername] = useState(Cookies.get('tempUsername'))
  const [tempEmail, setTempEmail] = useState(Cookies.get('tempEmail'))
  const [meetingRoomData, setMeetingRoomData] = useState(null)
  const [eventType, setEventType] = useState("")

  const [forget, setForget] = useState(null)
  const [signup, setSignup] = useState(null)
  const [reset, setReset] = useState(false)
  const [reset_token, setReset_token] = useState("")
  const [reset_pass, setReset_pass] = useState(false)
  const [isVerified, setIsVerified] = useState(Cookies.get('isVerified') === 'true' || Cookies.get('isVerified') === true);
  const [role, setRole] = useState(Cookies.get('role') ? Cookies.get('role') : 'child')
  const [forumsData, setForumsData] = useState(null)
  const [repliesData, setRepliesData] = useState(null)

  const [summaryData, setSummaryData] = useState(Cookies.get('summaryData') ? JSON.parse(Cookies.get('summaryData')) : null)

  const updateSummaryData = (summaryData_) => {

    setSummaryData(summaryData_);
    let tokenExpiration = new Date(new Date().getTime() + 3600 * 1000);
    try {
      Cookies.set('summaryData', JSON.stringify(summaryData_), { expires: tokenExpiration });
    } catch (error) {

    }


  }

  let RequestingReplies = false;
  let RequestingForums = false;

  const [modalActive, setModalActive] = useState(false);

  const startLoading = () => {
    setModalActive(true);
  };

  const stopLoading = () => {
    setModalActive(false);
  };
  
  const [canSubscribe, setCanSubscribe] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [currentValidSubscription, setCurrentValidSubscription] = useState(null);

  const updateSubscription = (canSubscribe_, currentSubscription_,currentValidSubscription_) => {
    setCanSubscribe(canSubscribe_);
    setCurrentSubscription(currentSubscription_);
    setCurrentValidSubscription(currentValidSubscription_);
    Cookies.set('canSubscribe', canSubscribe_, { expires: 1 });
    Cookies.set('currentSubscription', currentSubscription_, { expires: 1 });
    Cookies.set('currentValidSubscription', currentValidSubscription_, { expires: 1 });
  }

  const [authdatainfo, setAuthdatainfo] = useState('')
  const [attemptauth, setAttemptauth] = useState(false)

  const setauthattempts = (authdatainfo, attemptauth) => {

    setAuthdatainfo(JSON.stringify(authdatainfo))
    setAttemptauth(attemptauth)
  }

  const resetswitch = (reset_pass) => {
    setReset_pass(reset_pass);

  }

  const [mainScreen, setMainScreen] = useState(null)


  const [res_email, setRes_email] = useState(null)
  const [res_phone, setRes_phone] = useState(null)

  const [isloading, setIsloading] = useState(false)

  const setmainscreen = (mainScreen) => {
    setMainScreen(mainScreen);
  }

  const setload = (isloading) => {
    setIsloading(isloading);
  }

  const updateVerified = (verified) => {
    setIsVerified(verified);
    let tokenExpiration = new Date(new Date().getTime() + 3600 * 1000);
    Cookies.set('isVerified', verified, { expires: tokenExpiration });
  }

  const login = (token, userId, name, tokenExpiration, verified, email, role) => {

    setToken(token)
    setUserId(userId)
    setUsername(name)
    setIsVerified(verified)
    setEmail(email)
    setRole(role)
    tokenExpiration = new Date(new Date().getTime() + 3600 * 1000);
    Cookies.set('token', token, { expires: tokenExpiration });
    Cookies.set('userId', userId, { expires: tokenExpiration });
    Cookies.set('username', name, { expires: tokenExpiration });
    Cookies.set('isVerified', verified, { expires: tokenExpiration });
    Cookies.set('email', email, { expires: tokenExpiration });
    Cookies.set('tokenExpiration', tokenExpiration, { expires: tokenExpiration });
    Cookies.set('role', role, { expires: tokenExpiration });
  }

  const authswitch = (forget, signup, reset) => {
    setForget(forget)
    setSignup(signup)
    setReset(reset)
  }

  const authdetails = (res_email, res_phone, reset_token) => {
    setRes_email(res_email)
    setRes_phone(res_phone)
    setReset_token(reset_token)
  }

  const updateMeetingRoomData = (meetingRoomData_) => {
    setMeetingRoomData(meetingRoomData_)
  }

  const updateEventType = (eventType_) => {
    setEventType(eventType_)
  }

  const switchAccount = async (usernamed, emailed, option = true, updateOnly = false, rolez = "") => {
    let tokenExpiration = new Date(new Date().getTime() + 3600 * 1000);

    if (updateOnly) {
      //confirm switch to new user and seeting as new user (logged-in user)
      await Cookies.set('email', emailed, { expires: tokenExpiration });
      await Cookies.set('username', usernamed, { expires: tokenExpiration });
      await Cookies.set('role', rolez, { expires: tokenExpiration });


      setUsername(usernamed)
      setEmail(emailed)
      setRole(rolez)
      return true
    }

    if (option) {

      //keeping the old user details in temp

      await setTempEmail(email)
      await setTempRole(role)
      await setTempUsername(username)


      await Cookies.set('tempRole', role, { expires: tokenExpiration });
      await Cookies.set('tempUsername', username, { expires: tokenExpiration });
      await Cookies.set('tempEmail', email, { expires: tokenExpiration });

    } else {

      await setRole(tempRole ? tempRole : Cookies.get('tempRole'))
      await setUsername(tempUsername ? tempUsername : Cookies.get('tempUsername'))
      await setEmail(tempEmail ? tempEmail : Cookies.get('tempEmail'))
      let tokenExpiration = new Date(new Date().getTime() + 3600 * 1000);

      await Cookies.set('role', tempRole ? tempRole : Cookies.get('tempRole'), { expires: tokenExpiration });
      await Cookies.set('username', tempUsername ? tempUsername : Cookies.get('tempUsername'), { expires: tokenExpiration });
      await Cookies.set('email', tempEmail ? tempEmail : Cookies.get('tempEmail'), { expires: tokenExpiration });

      usernamed = tempUsername ? tempUsername : Cookies.get('tempUsername')
      emailed = tempEmail ? tempEmail : Cookies.get('tempEmail')

      await Cookies.remove('tempRole');
      await Cookies.remove('tempUsername');
      await Cookies.remove('tempEmail');

      //set the temp user details to the new user details
      await setTempEmail(null)
      await setTempRole(null)
      await setTempUsername(null)

    }

    startLoading();

    try {
      findUserInput.username = usernamed;
      findUserInput.email = emailed;
      let success = await loadUserSummary(findUserInput);
      stopLoading();
      if (success) {
        return true
      } else {
        return false
      }
    } catch (error) {
   
      stopLoading();
      return false
    }


  }


  const logout = () => {
    setToken(null)
    setUserId(null)
    setUsername(null)
    Cookies.remove('token');
    Cookies.remove('userId');
    Cookies.remove('username');
    Cookies.remove('isVerified');
    Cookies.remove('email');
    Cookies.remove('tokenExpiration');
    Cookies.remove('role');
    Cookies.remove('summaryData');
    Cookies.remove('tempRole');
    Cookies.remove('tempUsername');
    Cookies.remove('tempEmail');
    Cookies.remove('canSubscribe');
    Cookies.remove('currentSubscription');
    Cookies.remove('currentValidSubscription');

    //reset the state variables
    setToken(null)
    setUserId(null)
    setUsername(null)
    setIsVerified(false)
    setEmail(null)
    setRole(null)
  }


  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

  const bodyColor = prefersDarkMode ? 'dark' : 'light';
  const black = Colors[bodyColor].text;
  const white = Colors[bodyColor].background;

  const calor = white;


  const stylee = bodyColor != "light" ? "light" : "dark"

  let getForumsInput = {
    startIndex: 0,
    pageSize: 4
  }

  let getForumRepliesInput = {
    startIndex: 0,
    pageSize: 4
  }

  //  const windowHeight = Dimensions.get('window').height;
  const windowHeight = 1000;
  const decHeight = Math.round(0.1 * windowHeight);
  homeURL = process.env.REACT_APP_HOMEURL ? process.env.REACT_APP_HOMEURL : homeURL;
  const url =  homeURL 
  


  async function loadReplies(getForumRepliesInput, checking) {

    let baseUrl = url;

    try {


      if (!getForumRepliesInput.post) {
        return false
      }

      let requestBody = await getForumReplies(getForumRepliesInput, false, 'noKey');

      const resData = await userLoginRegisterRequest(requestBody, baseUrl);


      if (typeof resData === 'string' || resData instanceof String) {

        return false

      } else {

        if (checking && resData.data.postReplies_specific.length == 0) {
          return false
        } else {
          setRepliesData(await resData.data.postReplies_specific);
          return true
        }

      }

    } catch (error) {

      return false
    }
  }


  async function loadForums(getForumsInput, checking) {
    let baseUrl = url;

    try {


      // if (!getForumsInput.username){
      //   getForumsInput.username = Cookies.get('username') ? Cookies.get('username') : null
      // }

      if (!getForumsInput.username) {
        getForumsInput.username = '1234567'
      }

      let requestBody = await getForums(getForumsInput, false, 'noKey');

      const resData = await userLoginRegisterRequest(requestBody, baseUrl);


      if (typeof resData === 'string' || resData instanceof String) {

        return false

      } else {
        if (checking && resData.data.posts_specific.length == 0) {
          return false
        } else {
          setForumsData(await resData.data.posts_specific);
          return true
        }
      }

    } catch (error) {

      return false
    }
  }


  let findUserInput = {
    username: Cookies.get('username'),
    email: Cookies.get('email')
  }


  async function loadUserSummary(findRoomUserInput) {
    let baseUrl = url;

    try {
      let requestBody = await getUserSummary(findRoomUserInput, true, KeyLess);
      const resData = await userLoginRegisterRequest(requestBody, baseUrl);

      if (typeof resData === 'string' || resData instanceof String) {
        return false
      } else {

        setSummaryData(await resData.data.getUserSummary);
        return true
      }
    } catch (error) {
      return false
    }

  }

  const refreshPage = () => {
    const findUserInput = {
      username: Cookies.get('username'),
      email: Cookies.get('email'),
    }

    startLoading();
    loadUserSummary(findUserInput);
    stopLoading();
  }


  const loadAllForums = async () => {

    if (forumsData == null && !RequestingForums) {
      RequestingForums = true;
      await loadForums(getForumsInput);
      RequestingForums = false;
    }
  }

  useEffect(() => {
    loadAllForums();
  }, []);

  useEffect(() => {

    if (summaryData == null) {

      if (Cookies.get('username') && Cookies.get('email')) {

        findUserInput.username = Cookies.get('username');
        findUserInput.email = Cookies.get('email');


        loadUserSummary(findUserInput);
      }
    }
  }, []);

  return (
    <BrowserRouter>
      <GoogleAnalyticsTag trackingId="G-M72BD91GHM" />
      <Suspense fallback={<WaitingModal isActive={true} />}>
      <React.Fragment>
        <authContext.Provider value={{
          token: token, userId: userId, username: username, email: email, login: login, logout: logout, forget: forget, signup: signup, authswitch: authswitch,
          baseUrl: url, reset: reset, res_email: res_email, res_phone: res_phone, authdetails: authdetails, reset_token: reset_token, black: black,
          white: white, decHeight: decHeight, isloading: isloading, setload: setload, reset_pass: reset_pass, resetswitch: resetswitch, authdatainfo: authdatainfo, attemptauth:
            attemptauth, setauthattempts: setauthattempts, mainScreen: mainScreen, setMainScreen: setmainscreen, isVerified: isVerified, updateVerified: updateVerified, role: role, setRole: setRole,
          updateSummaryData: updateSummaryData, summaryData: summaryData, tempRole: tempRole, switchAccount: switchAccount, tempUsername: tempUsername,meetingRoomData: meetingRoomData, updateMeetingRoomData: updateMeetingRoomData,
          tempEmail: tempEmail, canSubscribe: canSubscribe, currentSubscription: currentSubscription, updateSubscription: updateSubscription, currentValidSubscription: currentValidSubscription, updateEventType: updateEventType, eventType: eventType,
        }}>
          <MainNavigation />
          <main className="main-content">
      
            <Switch>
              <Route path="/" exact render={(props) => <Home WaitingModal={WaitingModal} {...props} />} />
              <Route path="/demos" exact component={Demos} />
              <Route path="/features" exact component={Features} />
              {/* <Route path="/developers" exact component={Developers} /> */}
              <Route path="/pricing" exact component={Pricing} />
              {/* <Route path="/pricing" exact render={(props) => <Forums  {...props} />} /> */}
              <Route path="/forums" exact render={(props) => <Forums summaryData={summaryData} ForumsData={forumsData} RepliesData={repliesData} username={username} stopLoading={stopLoading} startLoading={startLoading} getForums={loadForums} getReplies={loadReplies} refreshPage={refreshPage} {...props} />} />
              {/* <Route path="/pricing" exact render={(props) => <Forums ForumsData={forumsData} username={username} stopLoading={stopLoading} startLoading={startLoading} getForums={loadForums} getReplies={loadReplies} {...props} />} /> */}
              {/* <Route path="/dashboard" exact component={Dashboard} /> */}
              <Route path="/dashboard" exact render={(props) => <Dashboard {...props} />} />
              <Route path="/login" exact component={Login} />
              <Route path="/verify" exact component={Verify} />
              {/* {token ? (
              <Route path="/dashboard" exact component={Dashboard} />
            ) : (
              <Route path="/login" exact component={Login} />
            )} */}
              <Route path="/signup" exact component={Signup} />
              <Route path="/forgotPassword" exact component={ForgotPassword} />
              <Route path="/meeting" exact render={(props) => <Chat {...props}  startLoading={startLoading} stopLoading={stopLoading} />} />
              <Route path="/subscription" exact render = {(props) => <SubscriptionPage {...props} startLoading={startLoading} stopLoading={stopLoading} />} />
              <Route path="/payment" exact render = {(props) => <PaymentPage {...props} startLoading={startLoading} stopLoading={stopLoading} />} />
              <Route path="/purchase" exact render = {(props) => <Purchase {...props} startLoading={startLoading} stopLoading={stopLoading} />} />
              <Route path="/support" exact component={Support} />
              <Route path="/recording" exact render={(props) => <Recordings {...props} startLoading={startLoading} stopLoading={stopLoading}  />} />
              
              <Route path="/security" exact component={Security} />
              <Route path="/video-codecs" exact component={VideoCodecs} />
              <Route path="/scaling" exact component={Scaling} />
              <Route path="/recommended-settings" exact component={RecommendedSettings} />
              <Route path="/about" exact component={AboutUs} />
              <Route path="/careers" exact component={Careers} />
              <Route path="/contact" exact component={Support} />
              <Route path="/prebuilt" exact component={MediaSFUPrebuilt} />
              <Route path="/streaming" exact component={Streaming} />
              <Route path="/privacy-policy" exact component={PrivacyPolicy} />
              <Route path="/terms-of-service" exact component={TermsOfService} />
              <Route path="/api-keys" exact component={APIKeys} />
              <Route path="/cloud-recording" exact component={CloudRecording} />

              <Route path="/subscription-info" exact render={(props) => <SubscriptionInfo {...props} />} />


              <Route path="/docs" exact component={Docs} />
              <Route path="/documentation" exact component={Documentation} />
              <Route path="/developers" exact component={Documentation} />
              <Route path="/sandbox" exact render={(props) => <Sandbox WaitingModal={WaitingModal} {...props} />} />
               <Route path="/basics" exact component={Starting} />
              <Route path="/components" exact render={(props) => <MediaSFUDocs WaitingModal={WaitingModal} {...props} />} />
              <Route path="/meeting/start" exact component={VideoCallSetup} />
              <Route path="/agents" exact component={AIPipelineGuide} />
              <Redirect to="/" />



            </Switch>
       

          <div>
            <WaitingModal isActive={modalActive} />
          </div>
          </main>
          {/* Conditionally render or hide the footer */}
        
        </authContext.Provider>
      </React.Fragment>
      </Suspense>
    </BrowserRouter>
  );

}

export default App;
