import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthContext from "../../../AuthContext/authContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt,
  faRocket,
  faHome,
  faRecordVinyl,
  faBroadcastTower,
  faCog,
  faKey,
  faBook,
  faCode,
  faShieldAlt,
  faBox,
  faCloud,
  faMobileAlt,
  faGraduationCap,
  faPuzzlePiece,
  faMobile,
  faLayerGroup,
  faCubes,
  faLaptopCode,
  faCalendarPlus,
  faCalendarCheck,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";

import "./MainNavigation.css";

// Example subscription/balance logic:
function checkSubscription(authContext) {
  return Boolean(authContext.activeKey?.status === "active");
}
async function checkBalance(balanceData, environment = "sandbox") {
  if (!balanceData) return false;
  let available = 0;
  switch (environment) {
    case "production":
      available = balanceData?.meetingMinutesActual?.available || 0;
      break;
    case "sandbox":
      available = balanceData?.meetingMinutesSandbox?.available || 0;
      break;
    case "demo":
      available = balanceData?.meetingMinutesDemo?.available || 0;
      break;
    default:
      return false;
  }
  return available > 0.5; // threshold
}

const MainNavigation = () => {
  const history = useHistory();
  const auth = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(false);
  const [showSubLinks, setShowSubLinks] = useState(true);
  const [subLinkName, setSubLinkName] = useState("");
  const [activeLink, setActiveLink] = useState(
    Cookies.get("activeLink") || "home"
  );

  // **State for showing dynamic messages** inside the EVENTS dropdown
  const [eventsMessage, setEventsMessage] = useState(null);

  const toggleNavbar = () => setIsOpen(!isOpen);

  const toggleSubLinks = (subLink) => {
    // Clear any existing events messages each time we mouse around
    // so the user doesn't see stale messages.
    setEventsMessage(null);
    setSubLinkName(subLink);
  };

  const setActiveLinked = (link) => {
    setActiveLink(link);
    Cookies.set("activeLink", link, { expires: 7 });
  };

  // On mount, if we have a cookie, set activeLink
  useEffect(() => {
    if (Cookies.get("activeLink")) {
      setActiveLink(Cookies.get("activeLink"));
    }
  }, []);

  // Keep the cookie updated
  useEffect(() => {
    Cookies.set("activeLink", activeLink, { expires: 7 });
  }, [activeLink]);

  // Basic route checks
  useEffect(() => {
    const defaultNames = [
      "home",
      "demos",
      "features",
      "developers",
      "pricing",
      "forums",
      "support",
      "docs",
      "dashboard",
      "login",
      "signup",
      "sandbox",
      "documentation",
      "security",
      "prebuilt",
      "terms-of-service",
      "privacy-policy",
      "contact-us",
      "about-us",
      "recording",
      "api-keys",
      "cloud-recording",
      "scaling",
      "recommended-settings",
      "video-codecs",
      "basics",
      "components",
    ];
    if (window.location.pathname === "/") {
      setActiveLink("home");
    } else {
      const path = window.location.pathname.substring(1);
      if (defaultNames.includes(path)) {
        setActiveLink(path);
      } else {
        setActiveLink("home");
      }
    }
  }, []);

  // Sign out
  const handleSignOut = (event) => {
    event.preventDefault();
    auth.logout();
    setActiveLink("login");
    Cookies.set("activeLink", "login", { expires: 7 });
    history.push("/login");
  };

  // Sublink data
  const subLinksData = {
    home: ["MediaSFU", "Recording", "Streaming", "API Keys"],
    developers: [
      "Documentation",
      "Basics",
      "Components",
      "SandBox (SDK)",
      "React SDK",
      "Flutter SDK",
      "Prebuilt",
      "React Native SDK",
      "React Native (Expo) SDK",
      "Angular SDK",
      "Vue SDK (Mar. 2025)",
      "Security",
      "Cloud Recording",
      "Agents",
      "Quick Start Apps",
      "Agents Apps",
    ],
  };
  const subLinkIcons = {
    MediaSFU: faHome,
    Recording: faRecordVinyl,
    Streaming: faBroadcastTower,
    "Agents Apps": faCog,
    "API Keys": faKey,
    Documentation: faBook,
    "SandBox (SDK)": faCode,
    Security: faShieldAlt,
    Prebuilt: faBox,
    "Cloud Recording": faCloud,
    Agents: faUserSecret,
    "Quick Start Apps": faRocket,
    "React SDK": faCode,
    "React Native SDK": faMobileAlt,
    "React Native (Expo) SDK": faMobile,
    "Flutter SDK": faLaptopCode,
    "Angular SDK": faCubes,
    "Vue SDK": faLayerGroup,
    Basics: faGraduationCap,
    Components: faPuzzlePiece,
  };
  const formatSubLinks = {
    MediaSFU: "/",
    Recording: "/recording",
    Streaming: "/streaming",
    "API Keys": "/api-keys",
    Documentation: "/documentation",
    Basics: "/basics",
    Components: "/components",
    "SandBox (SDK)": "/sandbox",
    "React SDK": "https://www.npmjs.com/package/mediasfu-reactjs",
    "Flutter SDK": "https://pub.dev/packages/mediasfu_sdk",
    "Angular SDK": "https://www.npmjs.com/package/mediasfu-angular",
    "Vue SDK": "/",
    Prebuilt: "/prebuilt",
    "React Native SDK": "https://www.npmjs.com/package/mediasfu-reactnative",
    "React Native (Expo) SDK":
      "https://www.npmjs.com/package/mediasfu-reactnative-expo",
    Security: "/security",
    "Cloud Recording": "/cloud-recording",
    Agents: "/agents",
    "Quick Start Apps": "https://github.com/mediasfu/mediasfu-quickstart-apps",
    "Agents Apps": "https://github.com/mediasfu/agents",
  };

  // ---------------------------------------
  // CREATE/JOIN LOGIC WITHOUT ALERTS
  // ---------------------------------------
  const handleCreateEvent = async () => {
    try {
      // Must be logged in
      if (!auth.token) {
        // Show a small "Please log in" message with an option to do a Demo environment
        setEventsMessage({
          type: "not-logged-in",
          text: "You need to be logged in to create an event.",
        });
        return;
      }
      const userRole = auth?.role || "guest";

      // Attempt Production
      const subscriptionValid = checkSubscription(auth);
      const hasProdBalance = await checkBalance(
        auth?.summaryData?.userBalance,
        "production"
      );

      if (subscriptionValid && hasProdBalance) {
        createRoom("production");
      } else {
        // Production not available
        if (userRole === "child" || userRole === "parent") {
          // No sandbox fallback
          setEventsMessage({
            type: "error",
            text: "Production isn't available, and your role can't create sandbox. Please top up or contact support.",
          });
        } else {
          // Try sandbox
          const hasSandboxBalance = await checkBalance(
            auth?.summaryData?.userBalance,
            "sandbox"
          );
          if (!hasSandboxBalance) {
            setEventsMessage({
              type: "error",
              text: "Insufficient sandbox minutes. Please top up or contact support.",
            });
          } else {
            createRoom("sandbox");
          }
        }
      }
    } catch (error) {
      console.error("Error creating event:", error);
      setEventsMessage({
        type: "error",
        text: "An error occurred. Please try again or contact support.",
      });
    }
  };

  const handleJoinEvent = () => {
    switchToMeeting();
  };

  const switchToMeeting = () => {
    Cookies.set("activeLink", "meeting", { expires: 7 });
    history.push("/meeting");
  };

  const createRoom = (environment) => {
    try {
      // environment: 'production' or 'sandbox'
      if (environment === "demo") {
        // Do something special for demo
        switchToMeeting();
        return;
      }
      const act_URL = "meeting";
      const roomType = "conference";

      let activeKey = auth?.summaryData?.userAPIKeys.find(
        (k) => k.type === environment
      );

      const meetingRoomData = {
        url: `https://${act_URL}.mediasfu.com/${act_URL}/start`,
        apiKey: activeKey?.apiKey || null,
        apiUserName: activeKey?.user?.username || null,
        username: auth?.username,
        eventType: roomType,
        environment: environment,
      };

      auth.updateMeetingRoomData(meetingRoomData);
      switchToMeeting();
    } catch (error) {
      console.error("Error creating room:", error);
      setEventsMessage({
        type: "error",
        text: "An error occurred. Please try again or contact support.",
      });
    }
  };

  // If they choose "Log In" from not-logged-in message
  const handleLoginClick = () => {
    setEventsMessage(null);
    setActiveLinked("login");
    history.push("/login");
  };

  // If they choose "Demo" from not-logged-in message
  const handleDemoClick = () => {
    // We'll create a 'demo' environment room or do something similar
    // Or just navigate them to a "demo" route
    setEventsMessage(null);
    createRoom("demo");
  };

  // Render the sub-menu for events
  const renderEventsSubMenu = () => {
    if (subLinkName !== "events" || !showSubLinks) return null;

    return (
      <div className="sub-links-card events-dropdown">
        <div className="row">
          <div
            className="col-12 sub-links-list events-create"
            style={{ cursor: "pointer" }}
          >
            <div onClick={handleCreateEvent}>
              <FontAwesomeIcon icon={faCalendarPlus} className="mr-4" /> Create
              Event
            </div>
          </div>
          <div
            className="col-12 sub-links-list events-join"
            style={{ cursor: "pointer" }}
          >
            <div onClick={handleJoinEvent}>
              <FontAwesomeIcon icon={faCalendarCheck} className="mr-4" /> Join
              Event
            </div>
          </div>
        </div>

        {/* If there's an eventsMessage, show a styled box below the options */}
        {eventsMessage && (
          <div className={`events-message fade-in ${eventsMessage.type}`}>
            {eventsMessage.text}

            {eventsMessage.type === "not-logged-in" && (
              <div className="events-message-actions">
                <button
                  onClick={handleLoginClick}
                  className="btn btn-sm btn-info mr-2"
                >
                  Log In
                </button>
                <button
                  onClick={handleDemoClick}
                  className="btn btn-sm btn-secondary"
                >
                  Proceed with Demo
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  // Render
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light mainNavigation">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src="/images/logo60.png" alt="Logo" />
        </a>
        <button
          className={`navbar-toggler ${isOpen ? "collapsed" : ""}`}
          type="button"
          onClick={toggleNavbar}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}>
          <ul
            className="navbar-nav navbar-nav-right"
            onMouseLeave={() => toggleSubLinks("")}
          >
            {/* Example: HOME with Sublinks */}
            <li
              className="new-navs nav-item"
              onMouseEnter={() => toggleSubLinks("home")}
            >
              <div
                className={`nav-link ${activeLink === "home" ? "active" : ""}`}
                onClick={() => setActiveLinked("home")}
              >
                HOME
                {subLinkName === "home" &&
                  showSubLinks &&
                  subLinksData.home && (
                    <div className="sub-links-card">
                      <div className="row">
                        {subLinksData.home.map((sl) => (
                          <div
                            key={sl}
                            className="col-sm-6 col-lg-6 mb-2 sub-links-list"
                          >
                            <FontAwesomeIcon
                              icon={subLinkIcons[sl]}
                              className="mr-2"
                            />
                            <a href={formatSubLinks[sl]}>{sl}</a>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
              </div>
            </li>

            {/* other main nav items: demos, features, etc. */}
            <li className="nav-item" onMouseEnter={() => toggleSubLinks("")}>
              <a
                className={`nav-link ${activeLink === "demos" ? "active" : ""}`}
                href="/demos"
                onClick={() => setActiveLinked("demos")}
              >
                DEMOS
              </a>
            </li>
            <li className="nav-item" onMouseEnter={() => toggleSubLinks("")}>
              <a
                className={`nav-link ${
                  activeLink === "features" ? "active" : ""
                }`}
                href="/features"
                onClick={() => setActiveLinked("features")}
              >
                FEATURES
              </a>
            </li>
            <li
              className="nav-item"
              onMouseEnter={() => toggleSubLinks("developers")}
            >
              <div
                className={`new-navs nav-link ${
                  activeLink === "developers" ? "active" : ""
                }`}
                href="/developers"
                onClick={() => setActiveLinked("developers")}
              >
                DEVELOPERS
                {subLinkName == "developers" &&
                  showSubLinks &&
                  subLinksData.developers && (
                    <div className="sub-links-card">
                      <div className="row">
                        {subLinksData.developers.map((subLink) => (
                          <div
                            key={subLink}
                            className="col-sm-6 col-lg-6 mb-2 sub-links-list"
                          >
                            <FontAwesomeIcon
                              icon={subLinkIcons[subLink]}
                              className="mr-2"
                            />
                            <a href={formatSubLinks[subLink]}>{subLink}</a>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
              </div>
            </li>
            <li className="nav-item" onMouseEnter={() => toggleSubLinks("")}>
              <a
                className={`nav-link ${
                  activeLink === "pricing" ? "active" : ""
                }`}
                href="/pricing"
                onClick={() => setActiveLinked("pricing")}
              >
                PRICING
              </a>
            </li>
            <li className="nav-item" onMouseEnter={() => toggleSubLinks("")}>
              <a
                className={`nav-link ${
                  activeLink === "forums" ? "active" : ""
                }`}
                href="/forums"
                onClick={() => setActiveLinked("forums")}
              >
                FORUMS
              </a>
            </li>
            <li className="nav-item" onMouseEnter={() => toggleSubLinks("")}>
              <a
                className={`nav-link ${
                  activeLink === "support" ? "active" : ""
                }`}
                href="/support"
                onClick={() => setActiveLinked("support")}
              >
                SUPPORT
              </a>
            </li>
            <li className="nav-item" onMouseEnter={() => toggleSubLinks("")}>
              <a
                className={`nav-link ${activeLink === "docs" ? "active" : ""}`}
                href="/docs"
                onClick={() => setActiveLinked("docs")}
              >
                DOCS
              </a>
            </li>

            {/* -------------- EVENTS NAV ITEM --------------- */}
            <li
              className="nav-item"
              onMouseEnter={() => toggleSubLinks("events")}
              onMouseLeave={() => toggleSubLinks("")}
            >
              <div
                className={`new-navs nav-link ${
                  activeLink === "events" ? "active" : ""
                }`}
                onClick={() => setActiveLinked("events")}
              >
                EVENTS
              </div>
              {renderEventsSubMenu()}
            </li>

            {/* Auth / Dashboard */}
            <li className="nav-item" onMouseEnter={() => toggleSubLinks("")}>
              {auth.token ? (
                <div className="d-flex">
                  <a
                    className={`nav-link ${
                      activeLink === "dashboard" ? "active" : ""
                    }`}
                    href="/dashboard"
                    onClick={() => setActiveLinked("dashboard")}
                  >
                    DASHBOARD
                  </a>
                  <a
                    className="nav-link"
                    href="/signout"
                    onClick={handleSignOut}
                  >
                    SIGN OUT
                  </a>
                </div>
              ) : (
                <div className="d-flex">
                  <a
                    className={`nav-link ${
                      activeLink === "login" ? "active" : ""
                    }`}
                    href="/login"
                    onClick={() => setActiveLinked("login")}
                  >
                    <FontAwesomeIcon
                      icon={faSignInAlt}
                      className="mr-2"
                      size="lg"
                    />
                    LOGIN
                  </a>
                  <a
                    className={`nav-link btn btn-secondary ${
                      activeLink === "signup" ? "active" : ""
                    }`}
                    href="/signup"
                    onClick={() => setActiveLinked("signup")}
                  >
                    <FontAwesomeIcon
                      icon={faRocket}
                      className="mr-2"
                      size="lg"
                    />
                    START
                  </a>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default MainNavigation;
